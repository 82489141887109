<template>
    <div>
        <ProductList :products="products" :groups="groups"/>
    </div>
</template>

<script>
    import ProductList from '@/components/app/product/ProductList'

    import { mapGetters, mapMutations } from 'vuex'

    import ProductService from '@/services/products.service'

    export default {
        name: 'CatalogIndex',

        components: {
            ProductList,
        },

        directives: {},
        filters: {},
        extends: {},
        mixins: {},
        props: {},
        data() {
            return {}
        },
        computed: {
            ...mapGetters({
                products: 'product/getProducts',
                groups: 'group/getGroups',
            }),

            appBarData() {
                return {
                    dark: true,
                    navigationIcon: {
                        type: 'nav',
                    },
                    title: 'Каталог',
                }
            },
        },
        watch: {},
        beforeCreate() {

        },
        created() {
            this.$store
                .dispatch('product/fetchProducts')
                .catch(error => { this.showToastError(error.errors) })
        },
        beforeMount() {

        },
        mounted() {
            this.setAppBarData()
        },
        beforeUpdate() {

        },
        updated() {

        },
        activated() {

        },
        deactivated() {

        },
        beforeDestroy() {

        },
        destroyed() {

        },
        methods: {
            ...mapMutations({
                setAppBar: 'ui/setAppBar',
            }),

            setAppBarData() {
                this.setAppBar(this.appBarData)
            },
        },
    }
</script>
